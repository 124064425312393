<template>
  <div class="d-flex flex-column mt-8">
    <div class="d-flex flex-column">
      <div class="d-flex justify-space-between">
        <block-text :text="passedData.type" color="secondary" type="body-1" />
        <div
          v-if="passedData.status.toLowerCase() === 'pending'"
          class="d-flex align-center"
        >
          <ph-arrows-clockwise
            class="pending-icon"
            :size="16"
            :color="'#888'"
          />
          <block-text
            :text="$t('dialog.pending')"
            class="ml-2"
            color="secondary"
            weight="bold"
            type="body-1"
          />
        </div>
      </div>
      <div class="d-flex mt-5 align-center">
        <v-img
          v-if="isMasterWallet"
          max-width="40"
          :src="require('@/assets/logo.svg')"
        />
        <v-avatar v-else color="rgba(190, 191, 192, 0.25)" size="40">
          <v-img
            v-if="passedData.userImage"
            max-width="40"
            :src="passedData.userImage"
          />
          <ph-user v-else :size="24" :color="'rgba(128, 128, 129, 1)'" />
        </v-avatar>
        <block-text
          v-if="isMyCommunity"
          :text="$t('activities.table.myCommunity')"
          weight="bold"
          class="ml-3 pt-1"
          :id="'userName'"
        />
        <block-text
          v-else
          :text="passedData.user"
          weight="bold"
          class="ml-3 pt-1"
          :id="'userName'"
        />
        <v-spacer></v-spacer>
        <div class="d-flex flex-column justify-end">
          <block-text
            :text="passedData.dateTime | convertToLocalFullDate"
            color="secondary"
            type="caption"
          />
          <block-text
            :text="passedData.dateTime | convertToLocalTime"
            class="text-right"
            color="secondary"
            type="caption"
          />
        </div>
      </div>
      <div
        v-if="passedData.otherWalletId && !isMyCommunity"
        class="d-flex flex-column flex-md-row align-start align-md-center mt-5"
      >
        <block-text
          :text="$t('dialog.accountNumber') + ':'"
          color="secondary"
          type="body-1"
        />
        <block-text
          :text="passedData.otherWalletId"
          class="ml-md-2 mt-1 mt-md-0"
          color="primary"
          type="body-1"
        />
      </div>
      <v-divider class="mt-5"></v-divider>
      <div class="d-flex justify-space-between mt-5">
        <div class="d-flex align-center">
          <block-text
            :text="$t('dialog.amount')"
            color="secondary"
            type="body-1"
            class="mr-2"
          />
          <div class="d-flex align-center">
            <div class="mr-2">
              <block-text
                :text="passedData.amount.toFixed(2)"
                color="primary"
                type="title"
                weight="bold"
              />
            </div>
            <div>
              <v-img
                width="30"
                height="30"
                class=""
                :src="require('@/assets/ttuToken.svg')"
              />
            </div>
          </div>
        </div>
        <div
          class="d-flex align-center cursor-pointer"
          @click="savePrintingData(passedData)"
        >
          <block-text
            :text="$t('dialog.print')"
            color="primary"
            weight="bold"
            type="body-1"
            class="mr-2"
          />
          <ph-printer
            :size="24"
            :color="$vuetify.theme.dark ? '#fff' : '#222'"
          />
        </div>
      </div>
      <v-divider
        v-if="passedData.transactionRequestNote"
        class="mt-5"
      ></v-divider>
      <div
        class="d-flex flex-column mt-5"
        v-if="passedData.transactionRequestNote"
      >
        <block-text
          :text="$t('dialog.personalMessage')"
          color="secondary"
          type="body-1"
        />
        <block-text :text="passedData.transactionRequestNote" />
      </div>
      <v-divider class="mt-5" v-if="passedData.transactionHeaderID"></v-divider>
      <div
        class="d-flex flex-column flex-md-row align-start align-md-center mt-5"
        v-if="passedData.transactionHeaderID"
      >
        <block-text
          :text="$t('dialog.transactionNumber') + ':'"
          color="secondary"
          type="body-1"
        />
        <block-text
          :text="passedData.transactionHeaderID"
          class="ml-md-2 mt-1 mt-md-0"
        />
      </div>
      <v-divider
        v-if="passedData.status.toLowerCase() === 'pending'"
        class="mt-5"
      ></v-divider>
      <div
        v-if="passedData.status.toLowerCase() === 'pending'"
        class="d-flex flex-column mt-5"
      >
        <block-text
          :text="$t('dialog.dialogFooterText1')"
          color="secondary"
          type="body-1"
        />
        <block-text
          :text="$t('dialog.dialogFooterText2')"
          color="secondary"
          type="body-1"
        />
      </div>
      <div class="mt-8 d-flex justify-center justify-md-end">
        <div
          v-if="
            passedData.status.toLowerCase() === 'pending' &&
            passedData.direction.toLowerCase() === 'income'
          "
          class="mr-3"
        >
          <v-btn
            @click.stop="toggleRejectDialog(passedData)"
            max-width="100"
            outlined
            rounded
            :elevation="0"
            text
            :color="'primary'"
            :ripple="false"
            :plain="false"
            class="text-capitalize body-1 px-10 mr-3 font-weight-bold text-center"
            >{{ $t("dialog.reject") }}
          </v-btn>
          <v-btn
            @click.stop="toggleAcceptDialog(passedData)"
            max-width="100"
            outlined
            rounded
            :elevation="0"
            text
            :color="'primary'"
            :ripple="false"
            :plain="false"
            class="text-capitalize body-1 px-10 font-weight-bold text-center"
            >{{ $t("dialog.accept") }}
          </v-btn>
        </div>
        <v-btn
          @click="closeDialog"
          max-width="100"
          outlined
          rounded
          :elevation="0"
          text
          :color="'primary'"
          :ripple="false"
          :plain="false"
          class="text-capitalize body-1 px-10 font-weight-bold text-center"
          >{{ $t("dialog.gotIt") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { PhArrowsClockwise, PhUser, PhPrinter } from "phosphor-vue";
import { mapGetters } from "vuex";
import dateConvertor from "@/mixins/dateConvertor";
export default {
  components: {
    PhArrowsClockwise,
    PhUser,
    PhPrinter,
  },
  mixins: [dateConvertor],
  props: {
    passedData: {
      default: null,
    },
  },
  data() {
    return {
      amount: "",
      walletToID: "",
      requestNotes: "",
      formSubmited: false,
      walletFromID: null,
    };
  },
  async mounted() {},
  computed: {
    ...mapGetters({
      user: "wallet/user",
    }),
    isMasterWallet() {
      return (
        this.passedData.user.toLowerCase() === "master wallet" ||
        this.passedData.user.toLowerCase() ===
          "tatatu enterprise ltd (master wallet)"
      );
    },
    isMyCommunity() {
      return (
        !this.passedData.user &&
        this.passedData.type.toLowerCase() === "donations"
      );
    },
  },
  methods: {
    savePrintingData(transaction) {
      localStorage.transactionType = transaction.type;
      localStorage.transactionDate = transaction.dateTime;
      localStorage.transactionStatus = transaction.status;
      localStorage.transactionNumber = transaction.transactionHeaderID;
      localStorage.transactionAmount = transaction.amount;
      localStorage.transactionAccountNumber = transaction.otherWalletId;
      localStorage.transactionUser = transaction.user;
      localStorage.transactionRequestNote = transaction.transactionRequestNote;

      this.closeDialog();
      this.$router.push({
        name: "print",
      });
    },
    closeDialog() {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
    },
    toggleRejectDialog(transaction) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ConfirmDialog",
        payload: {
          dialogType: "reject",
          dialogIcon: "PhXCircle",
          direction: 'income',
          transaction: transaction,
          text1: this.$t("dialog.rejectText1", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          text2: this.$t("dialog.rejectText2", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          ctaText: this.$t("dialog.confirm"),
        },
      });
    },
    toggleAcceptDialog(transaction) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ConfirmDialog",
        payload: {
          dialogType: "accept",
          dialogIcon: "PhCheckCircle",
          direction: 'income',
          transaction: transaction,
          text1: this.$t("dialog.acceptText1", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          text2: this.$t("dialog.acceptText2", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          ctaText: this.$t("dialog.confirm"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  svg {
    width: 24px;
    height: 24px;
  }
}
</style>
